import React, {useEffect} from "react";
import styles from "./AgreementPage.module.scss";
import useWindowSize from "../../hooks/useWindowSize";
import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import Footer from "../../components/footer/Footer";
import {useTranslation} from "react-i18next";

const AgreementPage = () => {
  const { windowSize } = useWindowSize();
  const {t} = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className={styles.wrapper}>
      <Header className={styles.headerWrapper} />
      {windowSize !== "mobile" && <SideBar />}
      <div className={styles.container}>
        <div className={styles.sectionWrapper}>
          <div className={styles.subtitle}>{t('agr1title')}</div>
          <div className={styles.text}>{t('1.1')}</div>
          <div className={styles.text}>{t('1.2')}</div>
          <div className={styles.text}>{t('1.3')}</div>
          <div className={styles.text}>{t('1.4')}</div>
          <div className={styles.text}>{t('1.5')}</div>
          <div className={styles.text}>{t('1.6')}</div>
          <div className={styles.text}>{t('1.7')}</div>
          <div className={styles.text}>{t('1.8')}</div>
          <div className={styles.subtitle}>{t('agr2title')}</div>
          <div className={styles.text}>{t('2.1')}</div>
          <div className={styles.text}>{t('2.2')}</div>
          <div className={styles.text}>{t('2.3')}</div>
          <div className={styles.text}>{t('2.4')}</div>
          <div className={styles.text}>{t('2.5')}</div>
          <div className={styles.text}>{t('2.6')}</div>
          <div className={styles.text}>{t('2.7')}</div>
          <div className={styles.text}>{t('2.8')}</div>
          <div className={styles.text}>{t('2.9')}</div>
          <div className={styles.text}>{t('2.10')}</div>
          <div className={styles.text}>{t('2.11')}</div>
          <div className={styles.text}>{t('2.12')}</div>
          <div className={styles.text}>{t('2.13')}</div>
          <div className={styles.subtitle}>{t('agr3title')}</div>
          <div className={styles.text}>{t('3.1')}</div>
          <div className={styles.text}>{t('3.2')}</div>
          <div className={styles.text}>{t('3.3')}</div>
          <div className={styles.text}>{t('3.4')}</div>
          <div className={styles.text}>{t('3.5')}</div>
          <div className={styles.text}>{t('3.6')}</div>
          <div className={styles.text}>{t('3.7')}</div>
          <div className={styles.text}>{t('3.8')}</div>
          <div className={styles.text}>{t('3.9')}</div>
          <div className={styles.text}>{t('3.10')}</div>
          <div className={styles.text}>{t('3.11')}</div>
          <div className={styles.text}>{t('3.12')}</div>
          <div className={styles.text}>{t('3.13')}</div>
          <div className={styles.subtitle}>{t('agr4title')}</div>
          <div className={styles.text}>{t('4.1')}</div>
          <div className={styles.text}>{t('4.2')}</div>
          <div className={styles.text}>{t('4.3')}</div>
          <div className={styles.text}>{t('4.4')}</div>
          <div className={styles.text}>{t('4.5')}</div>
          <div className={styles.text}>{t('4.6')}</div>
          <div className={styles.text}>{t('4.7')}</div>
          <div className={styles.text}>{t('4.8')}</div>
          <div className={styles.text}>{t('4.9')}</div>
          <div className={styles.text}>{t('4.10')}</div>
          <div className={styles.text}>{t('4.11')}</div>
          <div className={styles.text}>{t('4.12')}</div>
          <div className={styles.text}>{t('4.13')}</div>
          <div className={styles.text}>{t('4.14')}</div>
          <div className={styles.text}>{t('4.15')}</div>
          <div className={styles.text}>{t('4.16')}</div>
          <div className={styles.text}>{t('4.17')}</div>
          <div className={styles.text}>{t('4.18')}</div>
          <div className={styles.text}>{t('4.19')}</div>
          <div className={styles.subtitle}>{t('agr5title')}</div>
          <div className={styles.text}>{t('5.1')}</div>
          <div className={styles.text}>{t('5.2')}</div>
          <div className={styles.text}>{t('5.3')}</div>
          <div className={styles.text}>{t('5.4')}</div>
          <div className={styles.text}>{t('5.5')}</div>
          <div className={styles.text}>{t('5.6')}</div>
        </div>
        <div className={styles.sectionWrapper}>
          <div className={styles.subtitle}>{t('agr6title')}</div>
          <div className={styles.text}>
            ФОП Доніченко Андрій Вікторович <br/>
            м. Кропивницький, пров. Макаренка, буд. 22, 25011 <br/>
            Email: info@hiviewer.com
          </div>
        </div>
      </div>
      <Footer source={''}/>
    </section>
  );
};

export default AgreementPage;
