const PreLoginIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={51}
      height={50}
      fill="none"
    >
      <path
        fill="#282828"
        fillRule="evenodd"
        d="M25.5 47.024c12.163 0 22.024-9.86 22.024-22.024 0-12.163-9.86-22.024-22.024-22.024-12.163 0-22.024 9.86-22.024 22.024 0 12.163 9.86 22.024 22.024 22.024Zm0 2.976c13.807 0 25-11.193 25-25S39.307 0 25.5 0 .5 11.193.5 25s11.193 25 25 25Z"
        clipRule="evenodd"
      />
      <path
        fill="#282828"
        fillRule="evenodd"
        d="M25.5 47.024c12.163 0 22.024-9.86 22.024-22.024 0-12.163-9.86-22.024-22.024-22.024-12.163 0-22.024 9.86-22.024 22.024 0 12.163 9.86 22.024 22.024 22.024Zm0 2.976c13.807 0 25-11.193 25-25S39.307 0 25.5 0 .5 11.193.5 25s11.193 25 25 25Z"
        clipRule="evenodd"
      />
      <path
        fill="#282828"
        fillRule="evenodd"
        d="M16.274 25a9.226 9.226 0 1 0 18.452 0h2.976c0 6.74-5.463 12.202-12.202 12.202-6.74 0-12.202-5.463-12.202-12.202h2.976Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default PreLoginIcon;
