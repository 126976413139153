import styles from "../../_styles/InfoBlock.module.scss"
import {infoBlockItems} from "../../../../constants/structure";
import InfoBlockIcons from "./InfoBlockIcons";
import React from "react";
import {useTranslation} from "react-i18next";

interface IProps {
  voteCount: number
  isMobile?: boolean
  likeHandler: (e: any, item: any, flag: boolean) => void,
  favouritesHandler: (e: any, item: any, flag: boolean) => void,
  reviewHandler: () => void,
  liked: boolean,
  inFavourites: boolean,
  item: any,
  shareHandler: () => void
}

const ChooseInfoBlock = ({
   voteCount,
   isMobile,
   likeHandler,
   reviewHandler,
   favouritesHandler,
   liked,
   inFavourites,
   item,
   shareHandler
}: IProps) => {
  const { i18n } = useTranslation();

  const valueHandler = (path: string, def: any) => {
    const savedValue = Math.round(voteCount * 0.05);
    const sharedValue = Math.round(savedValue * 0.05);

    if (path === "likes") {
      return voteCount
    }
    if (path === "secondaryShare") {
      return sharedValue
    }

    if (path === "saved") {
      return savedValue
    }

    return def
  }

  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'

  return (
    <div className={`${isMobile ? styles.itemInfoBarMobile : styles.itemInfoBar}`}>
      {infoBlockItems.map((infoBlockItem, index) => {
        return (
          <div
            id={infoBlockItem.path}
            key={infoBlockItem.path}
            onClick={(e) => {
              if (infoBlockItem.handler === 'like') {
                return likeHandler(e, item, liked)
              }

              if (infoBlockItem.handler === 'saved') {
                return favouritesHandler(e, item, inFavourites)
              }

              if (infoBlockItem.handler === 'share') {
                return shareHandler()
              }
            }}
            className={`${styles.infoBarItemWrapper} ${isMobile ? styles.infoBarItemWrapperMobile : ""}`}>
            <div className={styles.infoBarContentWrapper}>
              <InfoBlockIcons
                dir={dirData}
                isMobile={isMobile}
                iconName={infoBlockItem.path}
                liked={liked}
                inFavourites={inFavourites}
              />
              <div>{valueHandler(infoBlockItem.path, infoBlockItem.number)}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ChooseInfoBlock
