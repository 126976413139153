import styles from "../../supportChat/SupportChatPage.module.scss";
import AvatarBg from "../../../components/icons/avatarbg";
import TestimonialInput from "../../../components/shared/TestimonialInput/TestimonialInput";
import React from "react";
import {useTranslation} from "react-i18next";
import axiosInstance from "../../../api/axios";
import {useAppSelector} from "../../../store/store";
import CommentsIcon from "../../../components/icons/services/CommentsIcon";
import Button from "../../../components/shared/Button/Button";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../constants/routes";
import {setSuccessToast} from "../../../store/slices/modals.slice";
import {useDispatch} from "react-redux";

interface IProps {
  refetch: () => void;
  itemId: number;
  setOpenModal: any;
}

const AddReviewModalContent = ({ refetch, itemId, setOpenModal }:IProps) => {
  const [content, setContent] = React.useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useAppSelector((state: any) => state.auth.userData);

  const sendHandler = async () => {
    try {
      const resp = await axiosInstance.post(`/movies/${itemId}/reviews`, {
        content: content
      })
      if (resp.status === 201) {
        setContent('');
        setOpenModal(false);
        dispatch(setSuccessToast(true));
        refetch();
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className={styles.addReviewWrapper}>
      <div className={styles.reviewModalContentDecor} />
      {userData?.email ? (
        <>
          <div className={styles.addReviewModalTitle}>{t('revModal1')}</div>
          <div className={styles.addReviewModalTitleSub}>{t('revModal2')}</div>
          <div className={styles.avatarBlock}>
            <div className={styles.avatarMain}>
              <AvatarBg/>
              <span>{userData?.username?.[0] || 'U'}</span>
            </div>
            <div>
              <div className={styles.avatarBlockName}>{userData?.username || 'User'}</div>
              <div className={styles.avatarBlockId}>{userData?.email || ''}</div>
            </div>
          </div>
          <div className={styles.textWrapper}>
            <TestimonialInput
              placeholder={t('addComment')}
              valueHandler={setContent}
              sendHandler={sendHandler}
              avatarSize={0}
            />
          </div>
          <div className={styles.addReviewModalInfo}>{t('revModal3')}</div>
        </>
      ) : (
        <div className={styles.serviceModalContent}>
          <div className={styles.serviceModalIcon}>
            <CommentsIcon />
          </div>
          <div className={styles.addReviewModalTitle}>{t('commentAuthTitle')}</div>
          <div className={styles.addReviewModalTitleSub}>{t('commentAuthText')}</div>
          <Button className={styles.authBtn} title={t('auth')} submit={() => navigate(ROUTES.LOGIN)} />
        </div>
      )}
    </div>
  )
};

export default AddReviewModalContent;
