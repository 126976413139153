import {useTranslation} from "react-i18next";
import styles from "../styles/profile.module.scss";
import Button from "../../../components/shared/Button/Button";

const discount = '5%';

const MySubscriptionsCard = () => {
  const {t, i18n} = useTranslation();
  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'

  return (
    <div className={styles.mySubCardWrapper}>
      <div className={styles.mySubCardWrapperContent}>
        <div className={styles.mySubCardAmount}>
          <div dir={dirData} className={styles.mySubCardAmountTitle}>{t('threeMoths')}</div>
          {discount && <div className={styles.mySubDiscount}>{t('shortDiscount')} {discount}</div>}
        </div>
        <div className={styles.mySubCardExpiration}>
          <p>{t('nextPayment')}</p>
          <p>15.04.2024</p>
        </div>
      </div>
      <div className={styles.mySubCardWrapperContent}>
        <Button
          title={t('continueWithDiscount')}
          submit={() => {}}
          className={styles.mySubCardButton}
        />
        <div className={styles.mySubCardInfo}>
          <p>{t('from')} 85 {t('perMth')}</p>
          <p>97 {t('perMth')}</p>
        </div>
      </div>
    </div>
  )
};

export default MySubscriptionsCard;
