const RedPlus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={9}
      fill="none"
    >
      <path
        fill="#EF3500"
        fillRule="evenodd"
        d="M6.643.572a1.111 1.111 0 0 1 1.571 0L9 1.357A1.111 1.111 0 0 1 9 2.93L7.428 4.5 9 6.071a1.111 1.111 0 0 1 0 1.572l-.786.785a1.111 1.111 0 0 1-1.571 0L5.07 6.857 3.5 8.428a1.111 1.111 0 0 1-1.571 0l-.786-.785a1.111 1.111 0 0 1 0-1.572L2.714 4.5 1.143 2.929a1.111 1.111 0 0 1 0-1.572l.786-.785a1.111 1.111 0 0 1 1.57 0l1.572 1.571L6.643.572Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default RedPlus;
