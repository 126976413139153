import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type DefaultState = {
  commonList: any
  page: number,
  prevPage: number,
  periodValue: number[],
  periodValueUI: number[]
  genresValue: number[]
}

const initialState: DefaultState = {
  commonList: [],
  page: 1,
  prevPage: 0,
  periodValue: [1980, 2024],
  periodValueUI: [1980, 2024],
  genresValue: []
}

export const FavouritesListsSlice = createSlice({
  name: "lists",
  initialState: initialState,
  reducers: {
    setDataToFavouritesList: (state, action: PayloadAction<any>) => {
      state.commonList = [...state.commonList, ...action.payload];
    },
    setPrevFavouritesPage: (state, action: PayloadAction<number>) => {
      state.prevPage = action.payload
    },
    setFavouritesPage: (state, action: PayloadAction<number>) => {
      state.page = state.page + action.payload
    },
    cleanUpFavouritesPage: (state) => {
      state.page = 1;
      state.prevPage = 0;
      state.commonList = []
    },
    setPeriodFavouritesValue: (state, action: PayloadAction<number[]>) => {
      state.periodValue = action.payload
    },
    setPeriodFavouritesValueUI: (state, action: PayloadAction<number[]>) => {
      state.periodValueUI = action.payload
    },
    setGenresFavouritesValue: (state, action: PayloadAction<number[]>) => {
      state.genresValue = action.payload
    },
    resetGenresFavouritesValue: (state) => {
      state.genresValue = []
    }
  },
})

export default FavouritesListsSlice.reducer
export const {
  setDataToFavouritesList,
  setPrevFavouritesPage,
  setFavouritesPage,
  cleanUpFavouritesPage,
  setPeriodFavouritesValue,
  setPeriodFavouritesValueUI,
  setGenresFavouritesValue,
  resetGenresFavouritesValue
} = FavouritesListsSlice.actions
