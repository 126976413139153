import btn from "./button.svg";
import kb from "./keybord.svg";
import space from "./space.svg";
import reactions from "./reactions.svg";
import user from "./user.svg";
import swipe from "./swipe.svg";

import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import styles from "./tips.module.scss";
import SkipIcon from "./SkipIcon";
import Prev from "./Prev";
import Next from "./Next";
import {useTranslation} from "react-i18next";

const BTN = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const loaderSize = isMobile
    ? {width: '170px', height: '53px'} : {width: '75px', height: '75px'}
  return (
    <div>
      <object type="image/svg+xml" data={btn} style={loaderSize}>svg-animation</object>
    </div>
  )
}
const SWIPE = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const loaderSize = isMobile
    ? {width: '210px', height: '145px'} : {width: '75px', height: '75px'}
  return (
    <div>
      <object type="image/svg+xml" data={swipe} style={loaderSize}>svg-animation</object>
    </div>
  )
}
const KB = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const loaderSize = isMobile
    ? {width: '170px', height: '53px'} : {width: '110px', height: '110px'}
  return (
    <div>
      <object type="image/svg+xml" data={kb} style={loaderSize}>svg-animation</object>
    </div>
  )
}
const SPACE = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const loaderSize = isMobile
    ? {width: '170px', height: '53px'} : {width: '186px', height: '35px'}
  return (
    <div>
      <object type="image/svg+xml" data={space} style={loaderSize}>svg-animation</object>
    </div>
  )
}
const REACTIONS = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const loaderSize = isMobile
    ? {width: '300px', height: '55px'} : {width: '440px', height: '55px'}
  return (
    <div>
      <object type="image/svg+xml" data={reactions} style={loaderSize}>svg-animation</object>
    </div>
  )
}
const USER = () => {
  const loaderSize = {width: '85px', height: '85px'}
  return (
    <div>
      <object type="image/svg+xml" data={user} style={loaderSize}>svg-animation</object>
    </div>
  )
}

const TipsComponent = ({setGuide}: { setGuide: any }) => {
  const [currentTip, setCurrentTip] = React.useState(1);
  const { windowSize } = useWindowSize();
  const {t, i18n} = useTranslation();
  const isMobile = windowSize === "mobile";
  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'

  const handleIncrement = () => {
    if (currentTip < 3) {
      setCurrentTip((prev) => prev + 1);
    }
  };

  const handleDecrement = () => {
    if (currentTip > 1) {
      setCurrentTip((prev) => prev - 1);
    }
  };

  const finishGuideHandler = (tip: any, skip?: any) => {
    if (tip === 3 || skip) {
      setGuide(false);
      localStorage.setItem('guide', '1');
    }
  }

  const firstSectionContent = (isMob: any) => {
    if (!isMob) {
      return (
        <div>
          <div className={styles.heading}>
            {t('tip1')} <span>{t('tip2')}</span>
          </div>
          <div className={styles.firstSectionIcons}>
            <BTN/>
            <p>,</p>
            <KB/>
            <p>{t('tip3')}</p>
            <SPACE/>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className={styles.heading}>
          {t('tip4')} <span>{t('tip5')}</span> {t('tip6')} <span>{t('tip7')}</span>
        </div>
        <div className={styles.firstSectionIcons}>
          <SWIPE />
        </div>
      </div>
    )
  }
  return (
    <div className={styles.tipsWrapper}>
      {currentTip === 1 && firstSectionContent(isMobile)}
      {currentTip === 2 && (
        <div>
          <div className={styles.heading}>
            <span>{t('tip8')}</span> {t('tip9')}
          </div>
          <div className={styles.firstSectionIcons}>
          <REACTIONS/>
          </div>
        </div>
      )}
      {currentTip === 3 && (
        <div>
          <div className={styles.heading}>
            <span>{t('tip10')}</span> {t('tip11')} <span>HIVIEWER</span>
          </div>
          <div className={styles.firstSectionIcons}>
            <USER />
          </div>
        </div>
      )}
      <div className={styles.navWrapper}>
        <div onClick={dirData === 'rtl' ? handleIncrement : handleDecrement}>{dirData === 'rtl'
          ? <Next currentStep={currentTip}/> : <Prev currentStep={currentTip}/>}</div>
        {`${currentTip} / 3`}
        <div onClick={dirData === 'rtl' ? handleDecrement : handleIncrement}>{dirData === 'rtl'
          ? <Prev currentStep={currentTip}/> : <Next currentStep={currentTip}/>}</div>
      </div>
      <div
        onClick={() => finishGuideHandler(currentTip, true)}
        className={styles.skipWrapper}
      >{currentTip === 3 ? t('tip12') : t('tip13')} <SkipIcon/></div>
    </div>
  )
}

export default TipsComponent;
