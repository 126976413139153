import MasterLogo from "../../../components/icons/profile/masterLogo";
import VisaLogo from "../../../components/icons/profile/visaLogo";
import styles from "../styles/profile.module.scss";
import FormInput from "../../../components/shared/FormInput/FormInput";
import {ChangeEvent, useState} from "react";
import {useTranslation} from "react-i18next";

interface NewCardFormProps {
  showTitle?: boolean | undefined
}
const NewCardForm = ({showTitle}: NewCardFormProps) => {
  const [cardNumber, setCardNumber] = useState('');
  const [cardDate, setCardDate] = useState('');
  const [cardCvc, setCardCvc] = useState('');
  const {t, i18n} = useTranslation();

  const handleChangeCardNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    const onlyNumbers = e.target.value.replace(/\D/g, '');
    const formattedNumber = onlyNumbers.replace(/(.{4})/g, '$1 ').trim();
    setCardNumber(formattedNumber);
  };

  const handleChangeCardDate = (e: ChangeEvent<HTMLInputElement>): void => {
    const onlyNumbers = e.target.value.replace(/\D/g, '');
    let formattedDate = onlyNumbers;

    if (onlyNumbers.length >= 3) {
      formattedDate = `${onlyNumbers.slice(0, 2)} / ${onlyNumbers.slice(2, 4)}`;
    } else if (onlyNumbers.length >= 1) {
      formattedDate = onlyNumbers.slice(0, 2);
    }
    setCardDate(formattedDate);
  };

  const handleChangeCardCvc = (e: ChangeEvent<HTMLInputElement>): void => {
    const onlyNumbers = e.target.value.replace(/\D/g, '');
    setCardCvc(onlyNumbers);
  }

  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'

  return (
    <div className={`${styles.formWrapper}  ${showTitle ? styles.additionalClass : ""}`}>
      {!showTitle && (
        <div className={styles.formHeaderWrapper}>
          <div className={styles.formTitle}>{t('addCard')}</div>
          <div className={styles.cardsLogoWrapper}>
            <VisaLogo/>
            <MasterLogo/>
          </div>
        </div>
      )}
      <div className={`${styles.newCardForm}`}>
        <FormInput
          dirData={dirData}
          setValue={handleChangeCardNumber}
          value={cardNumber}
          placeHolder={'1234 1234 1234 1234'}
          name={'number'}
          maxLength={19}
        />
        <div className={styles.secondaryInputsWrapper}>
          <FormInput
            dirData={dirData}
            setValue={handleChangeCardDate}
            value={cardDate}
            placeHolder={t('cardPlace')}
            name={'date'}
            maxLength={7}
          />
          <FormInput
            dirData={dirData}
            setValue={handleChangeCardCvc}
            value={cardCvc}
            placeHolder={'CVV2 / CVC2'}
            name={'cvc'}
            maxLength={3}
          />
        </div>
      </div>
    </div>
  )
}

export default NewCardForm;
