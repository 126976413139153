import {useEffect, useState} from "react";
import {useLogin} from "../../hooks/useLogin";
import styles from "./Login.module.scss";
import Header from "../../components/header/Header";
import {Link} from "react-router-dom";
import Button from "../../components/shared/Button/Button";
import Footer from "../../components/footer/Footer";
import CodeInput from "../../components/shared/CodeInput/CodeInput";
import {useTranslation} from "react-i18next";
import {ROUTES} from "../../constants/routes";

const Confirmation = () => {
  const [inputValue, setInputValue] = useState({});
  const [seconds, setSeconds] = useState(60);
  const [code, setCode] = useState('');
  const {t} = useTranslation();

  const startTimer = () => {
    setSeconds(60);
  };

  const { loginStepTwo, loginStepOne } = useLogin();

  const inputValueHandler = (e: any, pastFlag: boolean | undefined) => {
    if (pastFlag) {
      e.preventDefault();
      const paste = e.clipboardData.getData('text');
      const pastedData = paste.slice(0, 4).split('');

      const data: any = {}
      pastedData.forEach((char: any, index: any) => {
        data[`${+e.target.id + index}`] = char;
      });
      setInputValue(data)

      return
    }

    const value = e.target.value;

    setInputValue({...inputValue, [`${e.target.id}`]: value});
  };

  useEffect(() => {
    setCode(Object.values(inputValue).join(''))
  }, [inputValue]);

  useEffect(() => {
    if (seconds === 0) return;

    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds]);


  return (
    <div className={styles.loginPageWrapper}>
      <Header className={styles.headerWrapper} />
      <div className={styles.loginPageContentWrapper}>
        <div className={styles.loginTitle}>{t('enterCode')}</div>
        <div className={styles.confirmDescriptionDesc}>
          {t('confirmTitle1')} <span>hiviewer</span> {t('confirmTitle2')}
          <span>{localStorage.getItem('contact')}</span>
        </div>
        <CodeInput value={inputValue} setValue={inputValueHandler} />
        <Link to={'/feed'} className={styles.confirmButtonWrapper} >
          <Button
            disabled={code.length < 4}
            className={styles.confirmButton}
            title={t('confirm')}
            submit={() => loginStepTwo({ code })}
          />
        </Link>
        <div className={styles.resendButtonWrapper}>
          <Button
            disabled={!!seconds}
            className={`${styles.resendButton} ${!seconds ? styles.resendButtonEnable : ''}`}
            title={`${t('resend')} `}
            submit={() => {
              loginStepOne({ emailOrPhone: localStorage.getItem('contact') as string })
              startTimer()
            }}
          />
          {seconds ? <div className={styles.timer}>({seconds})</div> : null}
        </div>
        <div className={styles.privacyCodeDescription}>
          <div>
            {t('confirmFooter1')}
          </div>
          <div className={styles.contactUs}>{t('confirmFooter2')}</div>
          <Link to={ROUTES.SUPPORT}>{t('confirmFooter3')}</Link>
        </div>
        <div className={styles.privacyConfirmation}>
          <div>
            {t('confirmFooter4')}
            <Link to={ROUTES.AGREEMENT}>
              {t('confirmFooter5')}
            </Link>
          </div>
        </div>
      </div>
      <div style={{height: '50px'}}/>
      <Footer source={''} />
    </div>
  )
};

export default Confirmation;
