import { useState, useCallback } from 'react';
import axiosInstance from "../../api/axios";
import {useDispatch} from "react-redux/es/hooks/useDispatch";
import {setUpdateAvatar} from "../../store/api/authSlice";

interface UploadResult {
  success: boolean;
  data?: any;
  error?: string;
}

interface UseUploadPhotoReturn {
  uploadPhoto: (file: File) => Promise<UploadResult>;
  isLoading: boolean;
  error: string | null;
}

export const useUploadPhoto = (): UseUploadPhotoReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch();

  const uploadPhoto = useCallback(async (file: File): Promise<UploadResult> => {
    setIsLoading(true);
    setError(null);

    try {
      const formData = new FormData();
      formData.append('picture', file);

      const response = await axiosInstance.post(
        'user/picture/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      dispatch(setUpdateAvatar(response.data.picture))
      if (!response.status) {
        throw new Error(`Ошибка загрузки: ${response.statusText}`);
      }

      console.log('Успешно загружено:', response);

      return { success: true, data: response };
    } catch (err: any) {
      console.error('Ошибка при загрузке фото:', err);
      setError(err.message || 'Ошибка загрузки');
      return { success: false, error: err.message || 'Ошибка загрузки' };
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  return {
    uploadPhoto,
    isLoading,
    error,
  };
};
