import styles from "./Header.module.scss";
import {useRef} from "react";
import useOutsideClick from "../../utils/hooks/useOutsideClick";
import {useTranslation} from "react-i18next";

interface ILanguageDropDownProps {
  languageClickHandler: () => void;
  changeLanguage: (language: string) => void;
  openedFlag: boolean,
  leaveModalHandler: () => void;
}
const LanguageDropDown = ({
  languageClickHandler,
  changeLanguage,
  openedFlag,
  leaveModalHandler
}: ILanguageDropDownProps) => {
  const {i18n} = useTranslation();

  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, leaveModalHandler);

  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'
  return (
    <div
      dir={dirData}
      ref={modalRef}
      className={`${styles.localisationDropDown} ${openedFlag ? styles.opened : ''}`}
    >
      {openedFlag && (
        <>
          <div onClick={() => {
            changeLanguage('en');
            languageClickHandler();
          }}>
            English
          </div>
          <div onClick={() => {
            changeLanguage('uk');
            languageClickHandler();
          }}>
            Українська
          </div>
          <div onClick={() => {
            changeLanguage('ar');
            languageClickHandler();
          }}>
            العربية
          </div>
        </>
      )}
    </div>
  )
}

export default LanguageDropDown;
