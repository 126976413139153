import React, {useEffect, useRef} from "react";

import styles from "./MoviesList.module.scss";
import MovieCard from "./MovieCard";
import Heading from "../../../components/shared/Heading";
import DotsLoader from "../../../components/icons/DotsLoader";
import Search from "../../../components/icons/services/Search";
import {useTranslation} from "react-i18next";
import Selected from "../../../components/icons/services/Selcted";

interface ISimilarContent {
  error: any;
  data: any[];
  setPageHandler: () => void;
  isFetching?: boolean;
  isLastData?: boolean;
  likeHandler: (e: any, item: any, liked: boolean) => void;
  favouritesHandler: (e: any, item: any, isFav: boolean) => void;
  title: string;
  likesList: number[]
  favoritesList: number[]
  isFavoritePage: boolean
}

export interface Movie {
  id: number;
  genre_ids: number[];
  reviews_count: number | null;
  adult: boolean;
  backdrop_path: string;
  original_language: string;
  original_title: string;
  overview: string;
  overview_ru: string;
  overview_ua: string;
  popularity: number;
  poster_path: string;
  release_date: string;
  title: string;
  title_ru: string;
  title_ua: string;
  video: boolean;
  vote_average: number;
  vote_count: number;
  not_found: boolean;
  liked: boolean;
  in_favorite: boolean;
}

const MoviesList = ({
  data,
  setPageHandler,
  isFetching,
  error,
  isLastData,
  likeHandler,
  favouritesHandler,
  title,
  likesList,
  favoritesList,
  isFavoritePage,
}: ISimilarContent) => {
  const paginationAnchorRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (isFetching) return;

    const elem = paginationAnchorRef.current;
    const handleIntersection = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting && data.length && !error) {
          setPageHandler();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      threshold: 0.1
    });

    if (elem) {
      observer.observe(elem);
    }

    return () => {
      if (elem) {
        observer.unobserve(elem);
      }
    };
  }, [setPageHandler, isFetching, data?.length, error]);

  const emptyPageContent = () => {
    if (!isFavoritePage) {
      return (
        <div className={styles.emptyWrapper}>
          <Search/>
          <span>{t('emptySearch')}</span>
        </div>
      )
    }

    return (
      <div className={styles.emptyWrapper}>
        <Selected/>
        <span>{t('emptyFavourites')}</span>
      </div>
    )
  }

  return (
    <div className={styles.sectionWrapper}>
      <Heading title={title} renderFilters={true} isFavoritePage={isFavoritePage}/>
      {!data.length ? (
        emptyPageContent()
      ) : (
        <div className={styles.contentWrapper}>
          <div className={styles.cardListWrapper}>
            {data.map((item: Movie, index: number) => (
              <MovieCard
                item={item}
                key={index}
                isMobile={false}
                img={`${process.env.REACT_APP_IMG_BASE_URL}${item.poster_path}`}
                likeHandler={likeHandler}
                isFav={favoritesList?.includes(item.id)}
                liked={likesList?.includes(item.id)}
                favouritesHandler={favouritesHandler}
              />
            ))}
          </div>
          {!isLastData && <div className={styles.loaderWrapper} ref={paginationAnchorRef}><DotsLoader/></div>}
        </div>
      )}
    </div>
  );
};

export default MoviesList;
