import styles from "./SearchPage.module.scss";
import SideBar from "../../components/sideBar/SideBar";
import Input from "../../components/shared/Input";
import React, {useCallback, useEffect, useState} from "react";
import PeriodModule from "../filters/components/PeriodModule";
import useInterfaceContext from "../../components/providers/interface";
import {useGetSearchFilmsListQuery} from "../../store/api/filmAPI";
import {EN, UA} from "../../constants/local";
import GenresModule from "../filters/components/GenresModule";
import MoviesList from "./MoviesList/MoviesList";
import Footer from "../../components/footer/Footer";
import useWindowSize from "../../hooks/useWindowSize";
import Loader from "../../components/loader/Loader";
import Header from "../../components/header/Header";
import {useTranslation} from "react-i18next";
import SearchFieldIcon from "../../components/icons/searchIcons/searchField";
import {debounce} from "../../utils/helpers";
import {useDispatch} from "react-redux";
import {setSearch} from "../../store/slices/filters.slice";
import {useAppSelector} from "../../store/store";
import useLikeClick from "../../hooks/useLikeClick";
import useFavouriteClick from "../../hooks/useFavouriteClick";
import {
  cleanUpPage,
  setDataToList,
  setGenresValue,
  setPage,
  setPeriodValue,
  setPeriodValueUI,
  setPrevPage,
  setSortBy
} from "../../store/slices/lists.slice";

const buttonsList = [
  { title: "Popularity" },
  { title: "Rating" },
  { title: "date" },
];

interface IQuery {
  language: string;
  sort_by: string;
  include_video: boolean;
  "release_date_gte"?: string;
  "release_date_lte"?: string;
  search?: string;
  with_genres?: string;
}

const SearchPage = () => {
  const search = useAppSelector((state: any) => state.filters.search)
  const filmList = useAppSelector((state: any) => state.list.commonList)
  const page = useAppSelector((state: any) => state.list.page)
  const sortBy = useAppSelector((state: any) => state.list.sortBy)
  const periodValue = useAppSelector((state: any) => state.list.periodValue)
  const periodValueUI = useAppSelector((state: any) => state.list.periodValueUI)
  const genresValue = useAppSelector((state: any) => state.list.genresValue)
  const prevPage = useAppSelector((state: any) => state.list.prevPage)

  const [searchValue, setSearchValue] = useState('');
  const [inputValue, setInputValue] = useState(search || '');
  const [activeChips, setActiveChips] = useState(sortBy?.title);
  const [isFocused, setIsFocused] = useState(false);

  const { isMobile } = useInterfaceContext();
  const { windowSize } = useWindowSize();
  const { t, i18n } = useTranslation();
  const { likesList, likeHandler } = useLikeClick();
  const { favoritesList, favouritesHandler } = useFavouriteClick();
  const dispatch = useDispatch();

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const clearGenresHandler = () => {
    dispatch(cleanUpPage());
    dispatch(setGenresValue([]));
  }

  const clearPeriodHandler = () => {
    dispatch(cleanUpPage());
    dispatch(setPeriodValue([1980, 2024]));
    dispatch(setPeriodValueUI([1980, 2024]));
  }

  const setGenresValueHandler = (itemId: any) => {
    dispatch(cleanUpPage());
    const result = genresValue.includes(itemId)
      ? genresValue.filter((id: any) => id !== itemId) : [...genresValue, itemId];
    dispatch(setGenresValue(result));
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setPeriodForRequest = useCallback(
    debounce((value: number[]) => {
      dispatch(cleanUpPage());
      dispatch(setPeriodValue(value));
    }, 400),
    []
  );

  const setPeriodHandler = (periodValue: number[]) => {
    dispatch(setPeriodValueUI(periodValue));
    setPeriodForRequest(periodValue);
  };

  const queryHandler = () => {
    const query: IQuery = {
      language: i18n.language === 'en' ? EN : UA,
      sort_by: "popularity.desc",
      include_video: true,
    };

    if (sortBy?.sort) {
      query.sort_by = sortBy.sort;
    }

    if (periodValue[0] !== 1980 || periodValue[1] !== 2024) {
      query["release_date_gte"] = `${periodValue[0]}-01-01`;
      query["release_date_lte"] = `${periodValue[1]}-12-31`;
    }

    if (searchValue) {
      query.search = searchValue;
    }

    if (genresValue.length) {
      query.with_genres = genresValue.join('|');
    }

    return query;
  };

  const { data, isLoading, isFetching, error } = useGetSearchFilmsListQuery({
    mainFilter: "/movies/discover",
    queries: {
      ...queryHandler(),
      page: page
    },
  },{
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!isFetching && data.page > prevPage) {
      dispatch(setDataToList(data.results));
      dispatch(setPrevPage(data.page));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isFetching]);

  const handleClickChips = (title: string) => {
    dispatch(cleanUpPage());
    setActiveChips(title);
    if (title === 'Rating') {
      dispatch(setSortBy({sort: 'vote_average.desc', title}));
    } else if (title === 'date') {
      dispatch(setSortBy({sort: 'release_date.desc', title}));
    } else if (title === 'Popularity') {
      dispatch(setSortBy({sort: 'popularity.desc', title}));
    }
  };

  const setPageHandler = () => {
    dispatch(setPage(1));
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(cleanUpPage());

    setInputValue(event.target.value);
    dispatch(setSearch(event.target.value));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <section>
        <Header className={styles.headerWrapper} />
        {windowSize !== "mobile" ? <SideBar /> : null}
        <div className={styles.container}>
          <div className={styles.leftColumnWrapper}>
            <div className={styles.leftColumnTopContent}>
              <div className={styles.topControlsWrapper}>
                <div className={`${styles.searchFieldWrapper} ${isFocused ? styles.focused : ''}`}>
                  <SearchFieldIcon />
                  <Input
                    placeHolder={t('search')}
                    className={styles.input}
                    labelClassName={styles.label}
                    value={inputValue}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles.chipsListWrapper}>
                  {buttonsList.map(({ title }, index) => (
                    <button
                      key={index}
                      className={`${styles.chipsButton} ${activeChips === title ? styles.chipsButtonActive : ''}`}
                      onClick={() => handleClickChips(title)}
                    >
                      <p className={styles.btnText}>{t(title)}</p>
                    </button>
                  ))}
                </div>
              </div>
              <MoviesList
                data={filmList}
                error={error}
                setPageHandler={setPageHandler}
                isFetching={isFetching}
                isLastData={data.page ? data.page === data.total_pages : false}
                likeHandler={likeHandler}
                favouritesHandler={favouritesHandler}
                title={t('search')}
                likesList={likesList?.data?.results}
                favoritesList={favoritesList?.data?.results}
                isFavoritePage={false}
              />
            </div>
          </div>
          {windowSize !== "mobile" && (
            <div className={styles.contentWrapper}>
              <PeriodModule
                fromPageFlag={true}
                setPeriodValue={setPeriodHandler}
                isMobile={!isMobile}
                className={styles.periodModule}
                period={periodValueUI}
                clearFiltersHandler={clearPeriodHandler}
              />
              <GenresModule
                fromPageFlag={true}
                setGenresValue={setGenresValueHandler}
                isMobile={!isMobile}
                className={styles.genresModule}
                genres={genresValue}
                clearFiltersHandler={clearGenresHandler}
              />
              <div className={styles.advertisement} />
            </div>
          )}
        </div>
        <Footer source={''} />
      </section>
    </>
  );
};

export default SearchPage;
