import styles from "./MobilePreLogin.module.scss";
import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import Footer from "../../components/footer/Footer";
import React from "react";
import MobileLinks from "../../components/footer/MobileLinks";
import {useTranslation} from "react-i18next";
import PreLoginIcon from "../../components/icons/profile/preLoginIcon";
import Button from "../../components/shared/Button/Button";
import { useNavigate } from "react-router-dom";
import {ROUTES} from "../../constants/routes";

const MobilePreLogin = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.preLoginWrapper}>
        <Header/>
        <SideBar/>
        <div className={styles.contentWrapper}>
          <div className={styles.mainContent}>
            <PreLoginIcon />
            <div className={styles.title}>{t('auth')}</div>
            <div className={styles.descr}>{t('loginOrRegText')}</div>
            <Button
              className={styles.btn}
              title={t('login')}
              submit={() => navigate(ROUTES.LOGIN)}
            />
          </div>
          <MobileLinks showCopy={true} />

        </div>
      </div>
      <Footer source={''}/>
    </>
  )
};

export default MobilePreLogin;
