import {useTranslation} from "react-i18next";
import Button from "../../../components/shared/Button/Button";
import styles from "../styles/profile.module.scss";
import MySubscriptionsCard from "./MySubscriptionsCard";
import {useDispatch} from "react-redux";
import {setOpenModal} from "../../../store/slices/modals.slice";

const length = 1;
const MySubscriptions = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const removeSubscriptionHandler = () => {
    dispatch(setOpenModal('removeSubscription'))
  }
  return !length ? (
    <div>
      <p className={styles.mySubsTitle}>{t('emptySubs')}</p>
      <Button
        title={t('chooseSub')}
        submit={() => {}}
        className={styles.newSubButton}
      />
    </div>
  ) : (
    <div>
      <p className={styles.mySubsTitle}>{t('subListTitle')}</p>
      <p className={styles.mySubsListTitle}>{t('mySubs')}</p>
      <div className={styles.subsListWrapper}>
        <MySubscriptionsCard />
      </div>
      <div className={styles.mySubsBottomMenu}>
        <p>{t('payHistory')}</p>
        <p onClick={removeSubscriptionHandler}>{t('removePayment')}</p>
      </div>
    </div>
  )
}

export default MySubscriptions
