import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type DefaultState = {
  commonList: any
  page: number,
  prevPage: number,
  sortBy: { sort: string, title: string};
  periodValue: number[],
  periodValueUI: number[]
  genresValue: number[]
}

const initialState: DefaultState = {
  commonList: [],
  page: 1,
  prevPage: 0,
  sortBy: { sort: 'popularity.desc', title: 'Popularity' },
  periodValue: [1980, 2024],
  periodValueUI: [1980, 2024],
  genresValue: []
}

export const ListsSlice = createSlice({
  name: "lists",
  initialState: initialState,
  reducers: {
    setInitialList: (state, action: PayloadAction<any>) => {
      state.commonList = action.payload
    },
    setDataToList: (state, action: PayloadAction<any>) => {
      state.commonList = [...state.commonList, ...action.payload];
    },
    setPrevPage: (state, action: PayloadAction<number>) => {
      state.prevPage = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = state.page + action.payload
    },
    setSortBy: (state, action: PayloadAction<{ sort: string, title: string }>) => {
      state.sortBy = action.payload
    },
    cleanUpPage: (state) => {
      state.page = 1;
      state.prevPage = 0;
      state.commonList = []
    },
    setPeriodValue: (state, action: PayloadAction<number[]>) => {
      state.periodValue = action.payload
    },
    setPeriodValueUI: (state, action: PayloadAction<number[]>) => {
      state.periodValueUI = action.payload
    },
    setGenresValue: (state, action: PayloadAction<number[]>) => {
      state.genresValue = action.payload
    },
    resetGenresValue: (state) => {
      state.genresValue = []
    }
  },
})

export default ListsSlice.reducer
export const {
  setInitialList,
  setDataToList,
  setPage,
  cleanUpPage,
  setSortBy,
  setPeriodValue,
  setPeriodValueUI,
  setGenresValue,
  setPrevPage,
  resetGenresValue
} = ListsSlice.actions
