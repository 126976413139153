import {useAppDispatch, useAppSelector} from "../../store/store";
import {useEffect, useState} from "react";
import styles from "./styles/FilterComponents.module.scss";
import Header from "../../components/header/Header";
import {ROUTES} from "../../constants/routes";
import GenresModule from "./components/GenresModule";
import Button from "../../components/shared/Button/Button";
import Footer from "../../components/footer/Footer";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";
import {
  cleanUpFavouritesPage,
  resetGenresFavouritesValue,
  setGenresFavouritesValue
} from "../../store/slices/favouritesList.slice";

const FiltersForFavourites = () => {
  const dispatch = useAppDispatch()
  const genresFavouritesValue = useAppSelector((state: any) => state.favouritesList.genresValue)

  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [genresLocalValue, setGenresLocalValue] = useState<any>([])

  useEffect(() => {
    setGenresLocalValue(genresFavouritesValue);
  }, [genresFavouritesValue]);

  const submitFilters = () => {
    dispatch(cleanUpFavouritesPage());
    dispatch(setGenresFavouritesValue(genresLocalValue))

    const timeout =  setTimeout(() => {
      navigate(-1);
      clearTimeout(timeout);
    }, 300)
  }

  const setGenresValueHandler = (itemId: any) => {
    setGenresLocalValue((currentIds: any) => {
      if (currentIds.includes(itemId)) {
        return currentIds.filter((currentId: any) => currentId !== itemId);
      } else {
        return [...currentIds, itemId];
      }
    });
    // dispatch(setTemporaryGenres(itemId))
  }

  const clearGenresHandler = () => {
    setGenresLocalValue([]);
    dispatch(resetGenresFavouritesValue());
  }

  return (
    <div className={styles.filtersModalWrapper}>
      <Header source={ROUTES.FILTERS} clickHandler={() => {}} className={styles.headerWrapperFilters}/>
      <div className={`${isMobile ? styles.contentWrapperMobile : styles.contentWrapper}`}>
        <GenresModule
          fromPageFlag={false}
          isMobile={isMobile}
          setGenresValue={setGenresValueHandler}
          genres={genresLocalValue}
          clearFiltersHandler={clearGenresHandler}
        />
        <div className={styles.btnsWrapper}>
          <Button submit={submitFilters} title={t('apply')} className={styles.submitButton}/>
        </div>
      </div>
      <div style={{height: '50px'}}/>
      <Footer source={''}/>
    </div>
  )
};

export default FiltersForFavourites;
