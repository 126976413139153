import styles from "./ShareModal.module.scss";
import CopyIcon from "../../icons/discount/copy";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setShareModal} from "../../../store/slices/modals.slice";
import {copyToClipboard} from "../../../utils/helpers";

const ShareModal = ({ currentId }: {currentId: any}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'

  return (
    <div className={styles.modal} onClick={() => dispatch(setShareModal(false))}>
      <div className={styles.modalContent}>
        <div dir={dirData} className={styles.textWrapper}>
          <div className={styles.modalTitle}>{t('linkModal')}</div>
          <div className={styles.modalDescription}>{`https://hiviewer.com/details/${currentId}`}</div>
        </div>
        <div
          className={styles.copyIconWrapper}
          onClick={() => {
            copyToClipboard(`https://hiviewer.com/details/${currentId}`);
            dispatch(setShareModal(false))
          }}
        >
          <CopyIcon/>
        </div>
      </div>
    </div>
  )
};

export default ShareModal;
