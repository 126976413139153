import React, { FC } from "react";
import styles from "./Heading.module.scss";
import classnames from "classnames";
import ContentSwitcher from "../ContentSwitcher/ContentSwitcher";
import FilterIcon from "../../icons/Filters";
import useWindowSize from "../../../hooks/useWindowSize";
import {useAppSelector} from "../../../store/store";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../constants/routes";

export interface HeadingProps {
  title: string;
  counter?: string;
  classNameTitle?: string;
  classNameWrapper?: string;
  contentSwitcher?: boolean;
  setActiveTab?: (tab: string) => void;
  activeTab?: string;
  videosCounter?: number;
  imgsCounter?: number;
  renderFilters?: boolean;
  isFavoritePage?: boolean;
}

const Heading: FC<HeadingProps> = ({
  title,
  counter,
  classNameTitle,
  classNameWrapper,
  contentSwitcher,
  setActiveTab,
  activeTab,
  videosCounter,
  imgsCounter,
  renderFilters,
  isFavoritePage
}) => {
  const hasCounter = Boolean(counter);
  const navigate = useNavigate();

  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";

  const genresValue = useAppSelector((state: any) => state.list.genresValue)
  const genresFavouritesValue = useAppSelector((state: any) => state.favouritesList.genresValue)

  const counterTitleRender = () => {
    return (
      hasCounter ? (
        <div className={classnames(styles.wrapper, classNameWrapper)}>
          <h2 className={classnames(styles.title, classNameTitle)}>{title}</h2>
          <p className={styles.counter}>{counter ? counter : 0}</p>
        </div>
      ) : (
        <h2 className={classnames(styles.title, classNameTitle)}>{title}</h2>
      )
    )
  }
  const switchContentButtonRender = () => {
    return (
      <ContentSwitcher
        videosCounter={videosCounter}
        imgsCounter={imgsCounter}
        setActiveTab={setActiveTab as (tab: string) => void}
        activeTab={activeTab}
      />
    )
  }

  const renderFilterButton = () => {
    const counter = isFavoritePage ? genresFavouritesValue?.length : genresValue?.length;

    return (
      <div
        onClick={() => navigate(isFavoritePage ? ROUTES.FILTERS_FOR_FAVOURITES : ROUTES.FILTERS_FOR_LIST)}
        className={styles.filterButton}
      >
        {isMobile && counter !== 0 && (
          <div className={styles.filterButtonCounter}>{counter}</div>
        )}
        <FilterIcon isMobile={isMobile}/>
      </div>
    )
  }

  return (
    <div className={styles.headingWrapper}>
      {counterTitleRender()}
      {contentSwitcher && switchContentButtonRender()}
      {renderFilters && isMobile && renderFilterButton()}
    </div>
  );
};

export default Heading;
