import styles from "../pages/foryou/_components/MobileComponents/MobileComponents.module.css";
import LoaderIcon from "./icons/mainLoader/loader.svg";
import React from "react";
import useWindowSize from "../hooks/useWindowSize";

const LettersLoader = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const loaderSize = isMobile
    ? {width: '170px', height: '53px'} : {width: '340px', height: '105px'}

  return (
    <div className={styles.loaderBg}>
      <object type="image/svg+xml" data={LoaderIcon} style={loaderSize}>svg-animation</object>
    </div>
  )
}

export default LettersLoader
