import styles from "./FiltersHeader.module.scss";
import FilterHeaderHash from "../../../../components/icons/forYou/FilterHeaderHash";
import {useAppSelector} from "../../../../store/store";
import {genresData} from "../../../../constants/structure";
import useInterfaceContext from "../../../../components/providers/interface";
import {useTranslation} from "react-i18next";

const FiltersHeader = () => {
  const { isMobile } = useInterfaceContext();
  const { t } = useTranslation();

  const genres = useAppSelector((state: any) => state.filters.genres)
  const period = useAppSelector((state: any) => state.filters.period)

  const filterCounterHandler = (gen: number[], per: number[]) => {
    let counter = 0;
    if (per[0] !== 1980 ||  per[1] !== 2024) {
      counter += 1;
    };

    counter += gen.length;

    return counter;
  }

  const filterGenresHandler = (gen: number[], genList: any) => {
    if (!gen.length) return [];

    const genresStrings: string[] = [];

    gen.forEach((item) => {
      const currentGenre = genList.find((genItem: any) => genItem.id === item);
      if (currentGenre) {
        genresStrings.push(currentGenre.name)
      }
    })

    return genresStrings.splice(0, Math.min(isMobile ? 3 : 5, genresStrings.length));
  }

  return (
    <div className={styles.filtersHeader}>
      <div className={styles.filtersHeaderLeftContent}>
        {filterCounterHandler(genres, period) === 0
          ? (
            <div className={styles.filtersLeftContentList}>
              <div className={styles.iconHashWrapper}>
                <FilterHeaderHash className={`${styles.iconClass} ${styles.iconExtraClass}`}/>
              </div>
              <span className={styles.allFilters}>{t('all')}</span>
            </div>
          ) : (
            <div className={styles.filtersLeftContentList}>
              <div className={styles.iconHashWrapper}>
                <FilterHeaderHash className={`${styles.iconClass} ${styles.iconExtraClass}`}/>
              </div>
              {filterGenresHandler(genres, genresData).map((item: string) => {
                return (
                  <span>{t(item)}</span>
                )
              })}
            </div>
          )
        }
      </div>
    </div>
  )
};

export default FiltersHeader;
