import React, {ReactNode} from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/store";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Choose from "./pages/foryou/page";
import { InterfaceProvider } from "./components/providers/interface/InterfaceProvider";
import SearchPage from "./pages/search/SearchPage";
import Login from "./pages/login/Login";
import { ROUTES } from "./constants/routes";
import CooperationPage from "./pages/cooperation/CooperationPage";
import AboutPage from "./pages/about/AboutPage";
import AgreementPage from "./pages/agreement/AgreementPage";
import Confirmation from "./pages/login/Confirmation";
import Profile from "./pages/profile/Profile";
import UsersPage from "./pages/adminPanel/users/UsersPage";
import ActivityPage from "./pages/adminPanel/activity/ActivityPage";
import PrePaymentPage from "./pages/adminPanel/prePayment/PrePaymentPage";
import Filters from "./pages/filters/page";
import Details from "./pages/details/page";
import "./i18n";
import Pay from "./pages/pay/Pay";
import Greetings from "./pages/greetings/Greetings";
import Discount from "./pages/discount/Discount";
import MobileAccountSettings from "./pages/MobileAccountSettings/MobileAccountSettings";
import MobilePaymentDetails from "./pages/MobilePaymentDetails/MobilePaymentDetails";
import SupportChatPage from "./pages/supportChat/SupportChatPage";
import Favourites from "./pages/favourites/favourites";
import NotFoundPage from "./pages/404/NotFoundPage";
import FiltersForLists from "./pages/filters/pageForLists";
import FiltersForFavourites from "./pages/filters/pageForFavourites";
import MobilePreLogin from "./pages/MobilePreLogin/MobilePreLogin";

type PrivateRouteProps = {
  children: ReactNode;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken ? <>{children}</> : <Navigate to={ROUTES.LOGIN} />;
};

const router = createBrowserRouter([
  {
    path: `${ROUTES.DETAILS}/:id`,
    element: <Details />,
  },
  {
    path: ROUTES.FILTERS,
    element: <Filters />,
  },
  {
    path: ROUTES.FOR_YOU,
    element: <Choose />,
  },
  {
    path: ROUTES.SEARCH,
    element: <SearchPage />,
  },
  {
    path: ROUTES.INDEX,
    element: <Navigate to={ROUTES.FOR_YOU} />,
    index: true,
  },
  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.CONFIRMATION,
    element: <Confirmation />,
  },
  {
    path: ROUTES.COOPERATION,
    element: <CooperationPage />,
  },
  {
    path: ROUTES.ABOUT_US,
    element: <AboutPage />,
  },
  {
    path: ROUTES.SUPPORT,
    element: <SupportChatPage />,
  },
  {
    path: ROUTES.AGREEMENT,
    element: <AgreementPage />,
  },
  {
    path: ROUTES.PROFILE,
    element: (
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.ACTIVITY,
    element: <ActivityPage />,
  },
  {
    path: ROUTES.USERS,
    element: (
      <PrivateRoute>
        <UsersPage />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PRE_PAYMENT,
    element: (
      <PrivateRoute>
        <PrePaymentPage />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PAY,
    element: (
      <PrivateRoute>
        <Pay />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.GREETINGS,
    element: <Greetings />,
  },
  {
    path: ROUTES.DISCOUNT,
    element: <Discount />,
  },
  {
    path: ROUTES.MOBILE_ACCOUNT_SETTINGS,
    element: (
      <PrivateRoute>
        <MobileAccountSettings />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.MOBILE_PAYMENT_DETAILS,
    element: (
      <PrivateRoute>
        <MobilePaymentDetails />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.SUPPORT_CHAT,
    element: <SupportChatPage />,
  },
  {
    path: ROUTES.FAVOURITES,
    element: <Favourites />,
  },
  {
    path: ROUTES.NOT_FOUND,
    element: <NotFoundPage />,
  },
  {
    path: ROUTES.FILTERS_FOR_LIST,
    element: <FiltersForLists />,
  },
  {
    path: ROUTES.FILTERS_FOR_FAVOURITES,
    element: <FiltersForFavourites />,
  },
  {
    path: ROUTES.MOBILE_PRE_LOGIN,
    element: <MobilePreLogin />,
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <InterfaceProvider>
        <RouterProvider router={router} />
      </InterfaceProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
