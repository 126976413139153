import styles from "../styles/profile.module.scss";
import Button from "../../../components/shared/Button/Button";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {ROUTES} from "../../../constants/routes";

interface IProps {
  id: number,
  amount: string,
  total: number,
  price: number,
  discount: string
}
const SubscriptionCard = ({ amount, total, price, discount }: IProps) => {
  const {t, i18n} = useTranslation();
  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'

  return (
    <div className={styles.subscriptionCardWrapper}>
      <div>
        <div className={styles.cartAmountWrapper}>
          <div className={styles.amount}>{amount}</div>
          {discount && <div dir={dirData} className={styles.discount}>{t('shortDiscount')} {discount}</div>}
        </div>
        <div className={styles.total}>{total} {t('perMth')}</div>
      </div>
      <div className={styles.cardRightContent}>
        <div className={styles.priceWrapper}>
          <div className={styles.price}>{price} <span>{t('uah')}</span></div>
          {discount && <div className={styles.regularPrice}>97 {t('uah')}</div>}
        </div>
        <div>
          <Link to={ROUTES.PAY}>
            <Button
              className={styles.connectButton}
              title={t('subscribe')}
              submit={() => {}}
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionCard;
