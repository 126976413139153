const CloseTrailer = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={70}
      height={70}
      fill="none"
    >
      <rect
        width={70}
        height={70}
        fill="#222"
        fillOpacity={0.8}
        rx={35}
        transform="matrix(0 -1 -1 0 70 70)"
      />
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeWidth={3}
        d="M42 42 28 28m14 0L28 42"
      />
    </svg>
  )
}

export default CloseTrailer;
