import styles from "../../_styles/InfoBlock.module.scss"

import desctopStyle from "./Desctop.module.scss"
import {firstSectionInfo, firstSectionInfoMobile} from "../../../../constants/structure";
import InfoBlockIcons from "../rightData/InfoBlockIcons";
import useInterfaceContext from "../../../../components/providers/interface";
import {useTranslation} from "react-i18next";

interface IProps {
  voteCount: number;
  extraClass?: string;
  likeHandler: (e: any, item: any, selectedFlag: boolean) => void;
  favouritesHandler: (e: any, item: any, selectedFlag: boolean) => void;
  inFavourites: boolean;
  liked: boolean;
  item: any
  shareHandler: () => void
}

const FirstSectionInfoBlock = ({
   voteCount,
   extraClass,
   likeHandler,
   favouritesHandler,
   liked,
   inFavourites,
   shareHandler,
   item
}: IProps) => {
  const {isMobile} = useInterfaceContext();
  const {t} = useTranslation();

  const valueHandler = (path: string, def: any, text: string | undefined) => {
    const savedValue = Math.round(voteCount * 0.05);
    const sharedValue = Math.round(savedValue * 0.05);

    if (path === "likesDetails") {
      return voteCount
    }
    if (path === "savedDetails") {
      return isMobile ? savedValue : <div style={{fontWeight: "bold"}}>{t('save')}</div>
    }
    if (path === "shareDetails") {
      return sharedValue
    }

    return def
  }

  const data = !isMobile ? firstSectionInfo : firstSectionInfoMobile;

  return (
    <div className={desctopStyle.infoIconsWrapper}>
      {data.map((infoBlockItem: any, index: number) => {
        return (
          <div
            id={infoBlockItem.path}
            key={infoBlockItem.path}
            onClick={(e: any) => {
              if (infoBlockItem.handler === 'like') {
                return likeHandler(e, item, liked)
              }

              if (infoBlockItem.handler === 'saved') {
                return favouritesHandler(e, item, inFavourites)
              }

              if (infoBlockItem.handler === 'share') {
                return shareHandler()
              }
            }}
            style={infoBlockItem.path === 'savedDetails' ? {fontSize: '12px'} : {}}
            className={`${styles.infoBarItemWrapper} ${extraClass}`}
          >
            {!isMobile ? (
                <div className={styles.infoBarContentWrapper}>
                  <InfoBlockIcons
                    isMobile={isMobile}
                    iconName={infoBlockItem.path}
                    inFavourites={inFavourites}
                    liked={liked}
                  />
                  <div>{valueHandler(infoBlockItem.path, infoBlockItem.number, infoBlockItem.additionalText)}</div>
                </div>
              ) : (
               <>
                 <InfoBlockIcons
                   isMobile={isMobile}
                   iconName={infoBlockItem.path}
                   inFavourites={inFavourites}
                   liked={liked}
                 />
                 <div>{valueHandler(infoBlockItem.path, infoBlockItem.number, infoBlockItem.additionalText)}</div>
               </>
              )
            }
          </div>
        )
      })}
    </div>
  )
}

export default FirstSectionInfoBlock
