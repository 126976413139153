import styles from "../../_styles/InfoBlock.module.scss"
import {MainLike} from "../../../../components/icons/reactionMenu/MainLike";
import Comments from "../../../../components/icons/reactionMenu/Comments";
import Share from "../../../../components/icons/reactionMenu/Share";
import Saved from "../../../../components/icons/reactionMenu/Saved";
import SecondaryShare from "../../../../components/icons/reactionMenu/SecondaryShare";
import SavedDetails from "../../../../components/icons/details/SavedDetails";
import {LikeDetails} from "../../../../components/icons/details/LikeDetails";
import ShareDetails from "../../../../components/icons/details/ShareDetails";

interface IProps {
  iconName: string
  isMobile?: boolean
  liked?: boolean
  inFavourites?: boolean
  dir?: string
}

const InfoBlockIcons = ({ iconName, isMobile, liked, inFavourites, dir }: IProps) => (
  <div dir={dir} className={`${styles.icon}`}>
    {iconName === "likes" && (<MainLike isMobile={isMobile} liked={liked} />)}
    {iconName === "comments" && (<Comments isMobile={isMobile} />)}
    {iconName === "share" && (<Share isMobile={isMobile} />)}
    {iconName === "secondaryShare" && (<SecondaryShare isMobile={isMobile} />)}
    {iconName === "saved" && (<Saved isMobile={isMobile} inFavourites={inFavourites} />)}
    {iconName === "savedDetails" && (<SavedDetails isMobile={isMobile} inFavourites={inFavourites} />)}
    {iconName === "shareDetails" && (<ShareDetails isMobile={isMobile} />)}
    {iconName === "likesDetails" && (<LikeDetails isMobile={isMobile} liked={liked} />)}
  </div>
)

export default InfoBlockIcons
