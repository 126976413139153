import styles from '../styles/Details.module.scss';
import CloseTrailer from "../../../components/icons/details/CloseThrailer";
import {useGetCurrentVideoQuery} from "../../../store/api/filmAPI";
import {EN, UA} from "../../../constants/local";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import MobileCover from "../../foryou/_components/MobileComponents/MobileCover";
import PlayerControllerWrapper from "../../foryou/_components/playerController/PlayerControllerWrapper";
import useWindowSize from "../../../hooks/useWindowSize";

interface IProps {
  closeHandler: () => void;
  item: any;
  externalUrl: string;
}

const TrailerModal = ({
  closeHandler,
  item,
  externalUrl,
}:IProps) => {
  const [url, setUrl] = useState("")
  const [muted, setMuted] = useState(true)
  const [player, setPlayer] = useState(null)
  const [time, setTime] = useState<number | number[]>(0)
  const [ready, setReady] = useState(false)
  const [maxValue, setMaxValue] = useState<number | number[]>(0)
  const [displayVolume, setDisplayVolume] = useState<boolean>(false)

  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const { i18n } = useTranslation();

  const { data } = useGetCurrentVideoQuery({
    queries: { language: i18n.language === 'en' ? EN : UA },
    id: item.id
  }, {
    skip: externalUrl !== ''
  });

  useEffect(() => {
    if (externalUrl) {
      setUrl(externalUrl);
      return;
    }

    const trailer = data?.results.find((item: any) => item.type === 'Trailer') || data?.results[0]
    setUrl(trailer ? trailer?.key : '')
  }, [data, externalUrl])

  useEffect(() => {
    const element = document.getElementById('mobileCover');
    if (!element || isMobile) return;

    let timerId: number | any = null;

    const handleMouseMove = (e: any) => {
      if (!displayVolume) setDisplayVolume(true);

      clearTimeout(timerId);
      timerId = setTimeout(() => {
        setDisplayVolume(false);
      }, 3000);
    };

    const handleMouseLeave = (event: any) => {
      if (element.contains(event.relatedTarget)) {
        return;
      }
      setDisplayVolume(false);
    };

    element.addEventListener('mousemove', handleMouseMove);
    element.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      element.removeEventListener('mousemove', handleMouseMove);
      element.removeEventListener('mouseleave', handleMouseLeave);
      clearTimeout(timerId);
    };
  }, [displayVolume, isMobile]);


  const mutedIconHandler = () => {
    setMuted(!muted)
  }
  return (
    <div className={styles.trailerModalWrapper}>
      <div className={styles.relWrapper}>
        <div className={styles.topCover} />
        <div
          onClick={closeHandler}
          className={styles.closeTrailerBtn}>
          <CloseTrailer/>
        </div>
        <div id={'mobileCover'} className={styles.coversWrapper}>
          <MobileCover
            mutedIconHandler={mutedIconHandler}
            muted={muted}
            setMuted={setMuted}
            ready={ready}
          />
          {!isMobile && (
            <PlayerControllerWrapper
              time={time}
              maxValue={maxValue}
              player={player}
              setTime={setTime}
              displayVolume={displayVolume}
            />
          )}
        </div>
        <div style={{width: '100%', height: '100vh'}} className={styles.plWrapper}>
          {url && (
            <ReactPlayer
              controls={false}
              width={"100%"}
              height={"100%"}
              playsinline={true}
              progressInterval={time as number}
              onProgress={(value: any) => setTime(Math.round(value.playedSeconds))}
              playing={true}
              loop={true}
              muted={muted}
              fullscreen={!isMobile}
              url={`https://www.youtube.com/watch?v=${url}`}
              onReady={(e: any) => {
                setPlayer(e["player"])
                const duration = e["player"].getDuration()
                setMaxValue(duration)
                setTimeout(() => {
                  setReady(true)
                }, 200)
              }}
            />
          )}
        </div>
        <div className={styles.bottomCover} />
      </div>
    </div>
  )
};

export default TrailerModal;
