import styles from "./Greetings.module.scss";
import LogoIcon from "../../components/icons/Logo";
import Footer from "../../components/footer/Footer";
import Button from "../../components/shared/Button/Button";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../constants/routes";
import {useTranslation} from "react-i18next";
const Greetings = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <div className={styles.greetingsPageWrapper}>
      <div className={styles.header}><LogoIcon/></div>
      <div className={styles.contentWrapper}>
        <div className={styles.title}>{t('greet4')}</div>
        <div className={styles.description}>
          {t('greet1')}<br/>
          <span>"{t('greet2')}"</span>
        </div>
        <Button
          title={t('greet3')}
          submit={() => navigate(ROUTES.PROFILE)}
          className={styles.btn}
        />
      </div>
      <div style={{height:'50px'}} />
      <Footer source={''}/>
    </div>
  )
}

export default Greetings;
