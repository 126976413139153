import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import styles from "./Discount.module.scss";
import DiscountIcon from "../../components/icons/discount/discountIcon";
import CopyIcon from "../../components/icons/discount/copy";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import GreenPlus from "../../components/icons/discount/greenPlus";
import RedPlus from "../../components/icons/discount/redPlus";
import SmallDiscount from "../../components/icons/discount/smallDiscount";

type data = {[key: string]: {[key: string]: string|boolean}[]}
const dataTranslations: data = {
  'uk': [
    {
      mounts: "Безкоштовно",
      costs: "0",
      continue: "далі 82 грн/міс.",
      point1: "1 000 трейлерів в підборі",
      point1Positive: false,
      point2: "З рекламою",
      point2Positive: false,
      point3: "",
      showDiscount: false,
    },
    {
      mounts: "1 місяць",
      costs: "49",
      continue: "далі 79 грн/міс.",
      point1: "Без знижки",
      point1Positive: false,
      point2: "100 000 трейлерів в підборі",
      point2Positive: true,
      point3: "Без реклами",
      point3Positive: true,
      showDiscount: false,
    },
    {
      mounts: "1 рік",
      costs: "549",
      continue: "далі 699 грн/рік.",
      point1: "Знижка 25%",
      point1Positive: true,
      point2: "100 000 трейлерів в підборі",
      point2Positive: true,
      point3: "Без реклами",
      point3Positive: true,
      showDiscount: true,
    },
  ],
  'en': [
    {
      mounts: "Free",
      costs: "0",
      continue: "then $3.49/month",
      point1: "1,000 trailers in selection",
      point1Positive: false,
      point2: "With ads",
      point2Positive: false,
      point3: "",
      showDiscount: false,
    },
    {
      mounts: "1 month",
      costs: "4.99",
      continue: "then $2.99/month",
      point1: "No discount",
      point1Positive: false,
      point2: "100,000 trailers in selection",
      point2Positive: true,
      point3: "No ads",
      point3Positive: true,
      showDiscount: false,
    },
    {
      mounts: "1 year",
      costs: "54.99",
      continue: "then $69.99/year",
      point1: "25% discount",
      point1Positive: true,
      point2: "100,000 trailers in selection",
      point2Positive: true,
      point3: "No ads",
      point3Positive: true,
      showDiscount: true,
    },
  ],
  'ar': [
    {
      mounts: "مجاني",
      costs: "0",
      continue: "ثم 12.99 ريال/شهر",
      point1: "1,000 مقطع دعائي في التحديد",
      point1Positive: false,
      point2: "مع الإعلانات",
      point2Positive: false,
      point3: "",
      showDiscount: false,
    },
    {
      mounts: "شهر واحد",
      costs: "15.99",
      continue: "ثم 10.99 ريال/شهر",
      point1: "بدون خصم",
      point1Positive: false,
      point2: "100,000 مقطع دعائي في التحديد",
      point2Positive: true,
      point3: "بدون إعلانات",
      point3Positive: true,
      showDiscount: false,
    },
    {
      mounts: "سنة واحدة",
      costs: "149.99",
      continue: "ثم 199.99 ريال/سنة",
      point1: "خصم 25%",
      point1Positive: true,
      point2: "100,000 مقطع دعائي في التحديد",
      point2Positive: true,
      point3: "بدون إعلانات",
      point3Positive: true,
      showDiscount: true,
    },
  ],
};
const Discount = () => {
  const {t, i18n} = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'

  return (
    <div className={styles.discountPageWrapper}>
      <Header className={styles.headerDiscount}/>
      <div className={styles.discountContent}>
        <div className={styles.iconWrapper}><DiscountIcon/></div>
        <div className={styles.discountTitle}>{t('discountTitle')}</div>
        <div className={styles.discountDescription}>{t('offerTo')} 22.02.2025</div>
        <div className={styles.cards}>
          {dataTranslations[i18n.language || 'en'].map((item, index) => {
            return (
              <div dir={dirData} key={index} className={styles.cardWrapper}>
                <div className={styles.cardCosts}>
                  <span>{item.mounts}</span>
                  <span>{item.costs} <span className={styles.costCurrency}>{t('uah')}</span></span>
                </div>
                <div className={styles.cardContinue}>{item.continue}</div>
                <div className={styles.cardPointsList}>
                  <div>
                    <div dir={dirData} className={styles.planPointItem}>
                      {item.point1Positive && item.showDiscount
                        ? <SmallDiscount/> : item.point1Positive && !item.showDiscount
                          ? <GreenPlus/> : <RedPlus/>
                      }
                      <span>{item.point1}</span>
                    </div>
                    <div dir={dirData} className={styles.planPointItem}>
                      {item.point2Positive ? <GreenPlus/> : <RedPlus/>}
                      <span>{item.point2}</span>
                    </div>
                    {item.point3 && (
                      <div dir={dirData} className={styles.planPointItem}>
                        {item?.point3Positive ? <GreenPlus/> : <RedPlus/>}
                        <span>{item.point3}</span>
                      </div>
                    )}
                  </div>
                  <div className={styles.cardButton}>{t('connect')}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div style={{height: '50px'}}/>
      <Footer source={''}/>
      {openModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <div className={styles.textWrapper}>
              <div className={styles.modalTitle}>{t('linkModal')}</div>
              <div className={styles.modalDescription}>https://hiviewer.ai/Y6xZa9Hn6pV</div>
            </div>
            <div onClick={() => setOpenModal(false)}><CopyIcon/></div>
          </div>
        </div>
      )}
    </div>
  )
};

export default Discount;
