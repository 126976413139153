import React, {useEffect} from "react";
import styles from "./AboutPage.module.scss";
import SideBar from "../../components/sideBar/SideBar";
import useWindowSize from "../../hooks/useWindowSize";
import Heading from "../../components/shared/Heading";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import {useTranslation} from "react-i18next";

const CooperationPage = () => {
  const { windowSize } = useWindowSize();
  const {t} = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Header className={styles.headerWrapper} />
      {windowSize !== "mobile" && <SideBar />}
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <Heading title={t('Aboutus')} />
          <p className={styles.sectionText}>
            {t('about1')}
          </p>
        </div>
        <div className={styles.sectionWrapper}>
          <p className={styles.sectionText}>
            {t('about2')}
          </p>
        </div>
        <div className={styles.sectionWrapper}>
          <p className={styles.sectionText}>
            {t('about3')}
          </p>
        </div>
        <div className={styles.sectionWrapper}>
          <p className={styles.sectionText}>
            {t('about4')}
          </p>
        </div>
      </div>
      <div style={{height: '50px'}} />
      <Footer source={''}/>
    </div>
  );
};

export default CooperationPage;
