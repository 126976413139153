import styles from "./SideBar.module.scss"
import Choose from "../icons/Choose";
import Search from "../icons/Search";
import Likes from "../icons/Likes";
import Account from "../icons/Account";
import Invite from "../icons/Invite";

interface IProps {
  iconName: string
  selectedItem: boolean
  isMobile?: boolean
}

const SideBarIcon = ({ iconName, selectedItem, isMobile }: IProps) => {
  return (
    <div className={`${!isMobile && styles.icon}`}>
      {iconName === "feed" && (<Choose selected={selectedItem} isMobile={isMobile} />)}
      {iconName === "search" && (<Search selected={selectedItem} isMobile={isMobile} />)}
      {iconName === "likes" && (<Likes selected={selectedItem} isMobile={isMobile} />)}
      {iconName === "favourites" && (<Likes selected={selectedItem} isMobile={isMobile} />)}
      {iconName === "profile" && (<Account selected={selectedItem} isMobile={isMobile} />)}
      {iconName === "invite" && (<Invite selected={selectedItem} isMobile={isMobile} showPercent={true}/>)}
      {iconName === "discount" && (<Invite selected={selectedItem} isMobile={isMobile} showPercent={true}/>)}
    </div>
  )
}

export default SideBarIcon
