import React from "react"

import styles from "./Media.module.scss"
import Heading from "../../../../components/shared/Heading";
import useFilmsEN from "../../../../hooks/useFilmsEN";
import PlayIcon from "../../../../components/icons/details/PlayIcon";
import DotsLoader from "../../../../components/icons/DotsLoader";

type Props = {
  sectionTitle: string
  item: any
  setOpenModal: any
  activeTab: any
  setActiveTab: any,
  setSelectedMedia: any
}

const Media = ({
   sectionTitle,
   item,
   setOpenModal,
   activeTab,
   setActiveTab,
   setSelectedMedia
}: Props) => {

  const { enData, isLoading } = useFilmsEN(item.id, true)

  if (isLoading) {
    return <DotsLoader />
  }

  return (
    <div className={styles.wrapper}>
      <Heading
        title={sectionTitle}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        counter={enData?.results.length}
        videosCounter={enData?.results.length}
      />
      <div id={'mediaWrapper'} className={styles.cardWrapper}>
        {enData?.results.map((video: any, index: number) => (
          <div
            className={styles.imgWrapper}
            onClick={() => {
              setSelectedMedia(video.key)
              setOpenModal(true)
            }}
            key={index}
          >
            <div
              className={styles.image}
              style={{backgroundImage: `url(https://i.ytimg.com/vi/${video?.key}/hqdefault.jpg)`}}
            />
            <div className={styles.playIcon}><PlayIcon extraClass={styles.pathClass}/></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Media
