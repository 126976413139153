import styles from "../../pages/profile/styles/profile.module.scss";
import {Link, useNavigate} from "react-router-dom";
import {ROUTES} from "../../constants/routes";
import React from "react";
import useWindowSize from "../../hooks/useWindowSize";
import {useTranslation} from "react-i18next";
import SupportIcon from "../icons/footer/SupprotIcon";

interface IProps {
  showCopy?: boolean;
}
const MobileLinks = ({showCopy}:IProps) => {
  const { windowSize } = useWindowSize();
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();


  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  return (
    <>
      {windowSize === 'mobile' && (
        <div className={styles.links}>
          <Link to={ROUTES.DISCOUNT}>{t('Subscriptions')}</Link>
          <Link to={ROUTES.AGREEMENT}>{t('agreement')}</Link>
          <Link to={ROUTES.ABOUT_US}>{t('Aboutus')}</Link>
          <Link to={ROUTES.COOPERATION}>{t('Cooperation')}</Link>
          <div className={styles.supportBtn} onClick={() => navigate(ROUTES.SUPPORT_CHAT)}>
            <SupportIcon/>
            {t('Usersupport')}
          </div>
          <div className={styles.langBtns}>
            <div
              className={`${i18n.language === 'en' ? styles.activeBtn : ''}`}
              onClick={() => changeLanguage('en')}>ENG</div>
            <div
              className={`${i18n.language === 'uk' ? styles.activeBtn : ''}`}
              onClick={() => changeLanguage('uk')}>UA</div>
          </div>
          {showCopy && <a className={styles.app} href="/">© 2024<span> HIVIEWER. </span>{t('copyright')}.</a>}
        </div>
      )}
    </>
  )
}
export default MobileLinks;
