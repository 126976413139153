import React, {useState} from "react";
import useInterfaceContext from "../../components/providers/interface";
import {useGetReviewsListQuery, useGetVideoDetailsQuery} from "../../store/api/filmAPI";
import {EN, UA} from "../../constants/local";
import styles from "../foryou/_components/DesctopComponents/Desctop.module.scss";
import Header from "../../components/header/Header";
import ButtonIcon from "../../components/icons/ButtonIcon";
import FirstSectionInfoBlock from "../foryou/_components/DesctopComponents/FirstSectionInfoBlock";
import MainActorsList from "../foryou/_components/DesctopComponents/MainActorsList";
import Media from "../foryou/_components/DesctopComponents/Media";
import SimilarContent from "../foryou/_components/DesctopComponents/SimilarContent";
import Footer from "../../components/footer/Footer";
import {useAppSelector} from "../../store/store";
import { useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useLikeClick from "../../hooks/useLikeClick";
import useFavouriteClick from "../../hooks/useFavouriteClick";
import InfoData from "./components/InfoData";
import Heading from "./components/Heading";
import Reviews from "./components/Reviews";
import { Transition } from "react-transition-group";
import ModalBackdrop from "../../components/shared/SliderModal/ModalBackDrop";
import ModalBody from "../../components/shared/SliderModal/ModalBody";
import ReviewModalContent from "./components/ReviewModalContent";
import AddReviewModalContent from "./components/AddReviewModalContent";
import axiosInstance from "../../api/axios";
import SuccessToast from "../../components/shared/SuccessToast/SuccessToast";
import ShareModal from "../../components/shared/ShareModal/ShareModal";
import {setShareModal} from "../../store/slices/modals.slice";
import {useDispatch} from "react-redux/es/hooks/useDispatch";
import LettersLoader from "../../components/LettersLoader";
import TrailerModal from "./components/TrailerModal";


const Details = () => {
  const [hoverButton, setHoverButton] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [activeTab, setActiveTab] = useState('0');
  const [openTrailerModal, setOpenTrailerModal] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<string>('');
  const [currentReview, setCurrentReview] = useState(undefined);
  const { isMobile } = useInterfaceContext();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { likesList, likeHandler } = useLikeClick();
  const { favoritesList, favouritesHandler } = useFavouriteClick();
  const duration = 150;

  const { shareModal } = useAppSelector((state: any) => state.modals);

  const reviews = useGetReviewsListQuery({
    queries: { language: i18n.language === 'en' ? EN : UA },
    id: id,
  })

  const { data, isLoading } = useGetVideoDetailsQuery({
    queries: { language: i18n.language === 'en' ? EN : UA },
    id: id,
  })
  console.log(data, 'data')
  const likeReviewHandler = async (reviewId: number, liked: boolean) => {
    try {
      let resp;
      if (liked) {
        resp = await axiosInstance.delete(`/movies/${data?.id}/reviews/${reviewId}`)
      } else {
        resp = await axiosInstance.post(`/movies/${data?.id}/reviews/${reviewId}`)
      }
      if (resp) {
        reviews?.refetch();
      }
    } catch (e) {
      console.log(e);
    }
  }

  const shareHandler = () => {
    dispatch(setShareModal(true))
  }
  const closeTrailerHandler = () => {
    setSelectedMedia('')
    setOpenTrailerModal(false)
  }
  if (isLoading) {
    return <div className={styles.mainLoaderWrapper}>
      <LettersLoader />
    </div>
  }

  const searchGoogle = () => {
    console.log(data, 'data')
    const query = `${t('watchPlaceholder')} ${data?.title}`
    window.open(`https://www.google.com/search?q=${encodeURIComponent(query)}`, "_blank")
  }
  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'

  return Object.keys(data).length ? (
    <div className={styles.desctopModal}>
      {shareModal && <ShareModal currentId={data.id} />}
      <Header source={'modal'} className={styles.headerWrapper}/>
      <SuccessToast />
      <div style={{overflow: "scroll"}}>
        <div
          className={styles.firstSection}
          style={{backgroundImage: `url(${process.env.REACT_APP_IMG_BASE_URL}${data?.backdrop_path})`}}>
          <div className={styles.firstSectionInfo}>
            <div
              dir={dirData}
              className={styles.posterWrapper}
              style={{backgroundImage: `url(${process.env.REACT_APP_IMG_BASE_URL}${data?.poster_path})`}}
            />
            <div className={styles.infoWrapper}>
              <InfoData
                title={data?.title}
                releaseDate={data?.release_date}
                genres={data?.genres}
                runtime={data?.runtime}
                voteAverage={data?.vote_average}
              />
              <div className={styles.infoWrapperControlls}>
                <button
                  dir={dirData}
                  onClick={searchGoogle}
                  className={styles.controllsMainButton}
                  onMouseLeave={() => setHoverButton(false)}
                  onMouseEnter={() => setHoverButton(true)}>
                  <span>{t('quickSearch')}</span>
                </button>
                {!isMobile && (
                  <button
                    onClick={() => setOpenTrailerModal(true)}
                    className={styles.outlineSecondaryButton}
                    onMouseLeave={() => setHoverButton(false)}
                    onMouseEnter={() => setHoverButton(true)}>
                    <ButtonIcon stroke={hoverButton}/>
                    <span>{t('trailer')}</span>
                  </button>
                )}
                {isMobile && (
                  <button
                    onClick={() => setOpenTrailerModal(true)}
                    className={styles.outlineButton}
                    onMouseLeave={() => setHoverButton(false)}
                    onMouseEnter={() => setHoverButton(true)}>
                    <ButtonIcon stroke={hoverButton}/>
                    <span>{t('trailer')}</span>
                  </button>
                )}
                <FirstSectionInfoBlock
                  item={data}
                  voteCount={data?.vote_count}
                  extraClass={styles.infoBarItemWrapperExtra}
                  favouritesHandler={favouritesHandler}
                  inFavourites={favoritesList.data?.results?.includes(data?.id)}
                  likeHandler={likeHandler}
                  shareHandler={shareHandler}
                  liked={likesList.data?.results?.includes(data?.id)}
                />
              </div>
            </div>
          </div>
        </div>
        {(data) && (
          <div dir={dirData} className={styles.contentWrapper}>
            <div className={styles.overviewDescription}>
              <Heading title={t('description')}/>
              <div className={styles.descText}>{data?.overview}</div>
            </div>
            <MainActorsList sectionTitle={t('starring')} />
            <Media
              setSelectedMedia={setSelectedMedia}
              sectionTitle={t('media')}
              item={data}
              setOpenModal={setOpenTrailerModal}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            {data && (<SimilarContent item={data}/>)}
            <Reviews
              data={reviews?.data?.results}
              loadingState={reviews?.isLoading}
              setOpenModal={setOpenModal}
              likeReviewHandler={likeReviewHandler}
              setCurrentReview={setCurrentReview}
            />
          </div>
        )}
      </div>
      <Footer source={''}/>
      <Transition in={openModal} timeout={duration}>
        {state => (
          <ModalBackdrop setOpenModal={setOpenModal} duration={duration} state={state}>
            <ModalBody duration={duration} state={state}>
              {openModal && currentReview ? (
                <ReviewModalContent
                  likeReviewHandler={likeReviewHandler}
                  review={currentReview}
                  reviewsList={reviews?.data?.results}
                />
              ) : (
                <AddReviewModalContent
                  setOpenModal={setOpenModal}
                  itemId={data?.id}
                  refetch={reviews?.refetch}
                />
              )}
            </ModalBody>
          </ModalBackdrop>
        )}
      </Transition>
      {openTrailerModal && <TrailerModal externalUrl={selectedMedia} item={data} closeHandler={closeTrailerHandler} />}
    </div>
  ) : <div/>
};

export default Details;
