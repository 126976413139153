import styles from "../styles/profile.module.scss";
import InputWithState from "../../../components/shared/InputWithState/InputWithState";
import {useEffect, useState} from "react";
import Button from "../../../components/shared/Button/Button";
import Toggle from "../../../components/shared/Toggle/Toggle";
import Check from "../../../components/icons/profile/Check";
import {useTranslation} from "react-i18next";
import CustomSelect from "../../../components/shared/CustomSelect/CustomSelect";
import useWindowSize from "../../../hooks/useWindowSize";
import {useAppSelector} from "../../../store/store";
import useAccount from "../../../hooks/useAccount";
import moment from "moment";
const items = ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"];
const itemsEn = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const months = items.map((item, index) => ({
  ru: item,
  en: itemsEn[index],
  num: index + 1
}));

const startYear = 1950;
const endYear = 2025;

const yearsArray = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
const years = yearsArray.reverse().map((item) => ({ value: item, label: item.toString() }));
// const createYearArray = () => {
//   return Array.from({ length: 2024 - 1970 + 1 }, (_, i) => {
//     const year: any = 1970 + i;
//     return { value: year, label: year.toString() };
//   });
// };

const createObjectArray = (items: string[]) => {
  return items.map((item, index: number) => ({ value: index + 1, label: item }));
};

const daysArray = Array.from({ length: 31 }, (_, i) => i + 1);
const createDaysObjectArray = (days: number[]) => {
  return days.map(day => ({ value: day, label: day.toString() }));
};

const getDaysInMonth = (year: string, month: string): {value: number, label: string}[] => {
  if (!year || !month) {
    return createDaysObjectArray(daysArray);
  }

  const date = moment(`${year}-${month}`, 'YYYY-MM');

  if (!date.isValid()) {
    throw new Error('Invalid year or month format');
  }
  const daysInMonth = date.daysInMonth();
  const numbersArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  return numbersArray.map(day => ({ value: day, label: day.toString() }));
};


const genders = [
  {value: "Чоловік", label: "Чоловік"},
  {value: "Жінка", label: "Жінка"},
  {value: "Таємниця", label: "Таємниця"}
]
const gendersEn = [
  {value: "male", label: "Male"},
  {value: "female", label: "Female"},
]

const AccountSettings = () => {
  const [inputValue, setInputValues] = useState<{[key: string]: any}>({});
  const {t, i18n} = useTranslation();
  const { windowSize } = useWindowSize();
  const { updateAccountDetails, showCheck } = useAccount();

  const userData = useAppSelector((state: any) => state.auth.userData);

  useEffect(() => {
    setInputValues({
      username: userData.username,
      email: userData.email,
      phone: userData.phone,
      birth_day: userData.birth_day,
      birth_month: userData.birth_month,
      birth_year: userData.birth_year,
      sex: userData.sex,
      accept_notifications: userData.accept_notifications
    })
  }, [userData]);

  const inputValuesHandler = (key: string, value: any) => {
    setInputValues((prevState) => ({
      ...prevState,
      [key]: value
    }))
  }

  const checkedHandler = (value: any) => {
    inputValuesHandler('accept_notifications', value.target.checked);
  }
  const userBirthDay = userData.birthday?.split('-');
  const daysInCurrentMonth = getDaysInMonth(inputValue?.birth_year || userBirthDay?.[0] || '2024', inputValue.birth_month || userBirthDay?.[1] || '01')
  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'

  return (<>
    <div className={styles.accountTitle}>{t('settings')}</div>
    <div>
      <InputWithState
        value={inputValue?.username || ''}
        handler={(event) => inputValuesHandler('username', event.target.value)}
        type={'text'}
        placeholder={t('nick')}
        label={t('nick')}
      />
      <InputWithState
        value={inputValue?.email || ''}
        handler={(event) => inputValuesHandler('email', event.target.value)}
        type={'text'}
        placeholder={t('Email')}
        label={t('Email')}
      />
    </div>
    <div className={styles.dateTitle}>{t('birth')}</div>
    <div className={styles.selectWrapper}>
      <CustomSelect //@ts-ignore
        options={daysInCurrentMonth}
        maxHeight={170}
        label={userBirthDay ? userBirthDay[2] : daysInCurrentMonth[0]?.label}
        width={windowSize === 'mobile' ? '108px' : '157px'}
        inputValuesHandler={inputValuesHandler}
        savingKey={'birth_day'}
      />
      <CustomSelect //@ts-ignore
        options={i18n.language === 'en' ? createObjectArray(itemsEn) : createObjectArray(items)}
        maxHeight={170}
        label={userBirthDay ? months[+userBirthDay[1] - 1]?.en : t('month')}
        width={windowSize === 'mobile' ? '108px' : '157px'}
        inputValuesHandler={inputValuesHandler}
        savingKey={'birth_month'}
      />
      <CustomSelect //@ts-ignore
        options={years}
        maxHeight={170}
        label={userBirthDay ? userBirthDay[0] : t('year')}
        width={windowSize === 'mobile' ? '108px' : '157px'}
        inputValuesHandler={inputValuesHandler}
        savingKey={'birth_year'}
      />
    </div>
    <div className={styles.dateTitle}>{t('gender')}</div>
    <CustomSelect
      options={i18n.language === 'en' ? gendersEn : genders}
      label={userData.sex}
      inputValuesHandler={inputValuesHandler}
      savingKey={'sex'}
    />
    <div className={styles.submitBlock}>
      <div>
        <div className={styles.submitBlockQuestion}>{t('recMessages')}</div>
        <div dir={dirData} className={styles.toggle}>
          <Toggle checked={inputValue?.accept_notifications} setChecked={checkedHandler} />
          <span className={styles.descr}>{t('with')} Email, SMS, Viber, Mobile-push</span>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          title={t('saveChanges')}
          submit={() => updateAccountDetails(inputValue)}
          className={styles.button}
        />
        <div
          dir={dirData}
          style={{opacity: showCheck ? '1' : '0'}}
          className={styles.checkmark}
        >
          <Check /> {t('changesSaved')}
        </div>
      </div>
    </div>
  </>)
}

export default AccountSettings;
