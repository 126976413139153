import styles from "../styles/profile.module.scss";
import {useState} from "react";
import Subscriptions from "./Subscriptions";
import {useTranslation} from "react-i18next";
import MySubscriptions from "./MySubscriptions";

const PrepaymentPage = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const {t, i18n} = useTranslation();

  const currentTabHandler = (index: number) => {
    setCurrentTab(index)
  }

  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'

  return (
    <div>
      <div className={styles.accountTitle}>{t('subAndPay')}</div>
      <div className={styles.tabsBlock}>
        <div
          dir={dirData}
          onClick={() => currentTabHandler(0)}
          className={`
            ${styles.tab}
            ${currentTab === 0 && styles.active}
            ${currentTab === 2 && styles.additionalClass}
          `}>{t('Subscriptions')}</div>
        <div
          onClick={() => currentTabHandler(2)}
          className={`${styles.tab} ${currentTab === 2 && styles.active}`}>{t('mySubs')}</div>
      </div>
      {currentTab === 0 && (<Subscriptions />)}
      {/*{currentTab === 1 && (<MyCards />)}*/}
      {currentTab === 2 && (<MySubscriptions />)}
    </div>
  )
};

export default PrepaymentPage;
