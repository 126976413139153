import styles from "./Footer.module.scss";
import { useEffect, useState } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { footerMenuItems, ROUTES_WITHOUT_FOOTER } from "../../constants/structure";
import SideBarIcon from "../sideBar/SideBaricon";
import FooterLogo from "../icons/FooterLogo";
import useWindowSize from "../../hooks/useWindowSize";
import {ROUTES} from "../../constants/routes";
import {useTranslation} from "react-i18next";
import SupportIcon from "../icons/footer/SupprotIcon";
import {useAppSelector} from "../../store/store";

interface IProps {
  source: string;
}
const Footer = ({ source }: IProps) => {
  const [horizontal, setHorizontal] = useState(true);
  const { windowSize } = useWindowSize();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const userEmail = useAppSelector((state: any) => state.auth.userData.email);


  const focusHandler = () => {
    const chooseWrapper = document.getElementById("choose_section");

    if (chooseWrapper) {
      chooseWrapper.focus();
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener(
        "orientationchange",
        () => {
          if (window.innerHeight > window.innerWidth) setHorizontal(false);
          else setHorizontal(true);
        },
        false,
      );
    }

    return () => {
      window.removeEventListener("orientationchange", () => {});
    };
  }, []);
  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'
  const footerContentHandler = () => {
    if (windowSize !== "mobile" && !ROUTES_WITHOUT_FOOTER.includes(pathname)) {
      return (
        <div
          dir={dirData}
          className={`${styles.footerWrapper} ${source === "modal" || source === ROUTES.SEARCH
            ? styles.footerModalWrapper : ""}`}
          onClick={focusHandler}
        >
          <div className={styles.footerLogoWrapper}>
            <FooterLogo />
          </div>
          <div className={styles.links}>
            <Link to={ROUTES.DISCOUNT}>{t('Subscriptions')}</Link>
            {i18n.language !== 'en' && <Link to={ROUTES.AGREEMENT}>{t('agreement')}</Link>}
            <Link to={ROUTES.ABOUT_US}>{t('Aboutus')}</Link>
            <Link to={ROUTES.COOPERATION}>{t('Cooperation')}</Link>
          </div>
          <div dir={dirData} className={styles.copy}>
            <div dir={dirData} className={styles.supportBtn} onClick={() => navigate(ROUTES.SUPPORT_CHAT)}>
              <SupportIcon/>
              {t('Usersupport')}
            </div>
            <a href="/">© 2024<span>HIVIEWER.</span> {t('copyright')}.</a>
          </div>
        </div>
      );
    }

    if (windowSize === "mobile") {
      return (
        <div className={styles.footerWrapperMobile} style={!horizontal ? {display: "none" } : {}}>
          {footerMenuItems.map((item: any) => {
            const pathHandler = (path: string) => {
              if (path === 'profile') {
                return userEmail ? ROUTES.PROFILE : ROUTES.MOBILE_PRE_LOGIN
              }

              return `/${path}`;
            }

            const selectedItemHandler = () => {
              if (item.path === 'profile') {
                return pathname === '/profile' || pathname === '/login'
              }
              return pathname === `/${item.path}`
            }
            return (
              <Link key={item.path} to={pathHandler(item.path)} className={styles.itemWrapper}>
                <SideBarIcon
                  iconName={item.path}
                  selectedItem={selectedItemHandler()}
                  isMobile={windowSize === "mobile"}
                />
              </Link>
            );
          })}
        </div>
      );
    }

    return <div />;
  };

  return footerContentHandler();
};

export default Footer;
