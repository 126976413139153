import axios from 'axios';

const MAX_RETRIES = 5;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_MAIN_URL
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (originalRequest.url.includes('refresh/')) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401 && (!originalRequest._retry || originalRequest._retry < MAX_RETRIES)) {
      originalRequest._retry = (originalRequest._retry || 0) + 1;

      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        return Promise.reject(error);
      }

      try {
        localStorage.removeItem('accessToken');

        const response = await axiosInstance.post('refresh/', { refresh: refreshToken });
        if (response.status === 200) {
          localStorage.setItem('accessToken', response.data.access);
          axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access;

          return axiosInstance(originalRequest);
        } else {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
        }
      } catch (e) {
        console.error('Token refresh failed:', e);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
