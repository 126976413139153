import classnames from "classnames";
import styles from './Button.module.scss';
import {useTranslation} from "react-i18next";

interface IProps {
  title: string,
  submit: any,
  className?: string,
  disabled?: boolean
}
const Button = ({
  title,
  submit,
  className,
  disabled
}: IProps) => {
  const {i18n} = useTranslation();
  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'

  return (
    <button
      dir={dirData}
      disabled={disabled}
      className={classnames(styles.button, className)}
      onClick={submit}
    >
      {title}
    </button>
  )
};

export default Button;
