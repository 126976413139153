import axiosInstance from "../api/axios";
import {useDispatch} from "react-redux";
import {setUserData} from "../store/api/authSlice";
import { useState } from "react";

interface UserProfile {
  email: string;
  username: string;
  birth_day: string | null;
  birth_month: string | null;
  birth_year: string | null;
  address: string | null;
  sex: 'male' | 'female';
  active: boolean;
  fname: string | null;
  lname: string | null;
  accept_notifications: boolean;
  picture_url: string | null;
}

const useAccount = () => {
  const [showCheck, setShowCheck] = useState(false);
  const dispatch = useDispatch();

  const getAccountDetails = async () => {
    try {
      const response = await axiosInstance.get<UserProfile>('/user/profile/');
      dispatch(setUserData(response.data));
    } catch (e) {
      console.log(e);
    }
  }

  const updateAccountDetails = async (data: any) => {
    try {
      const response = await axiosInstance.patch<UserProfile>('/user/profile/', data);

      if (response.status === 200) {
        setShowCheck(true);
        const timer = setTimeout(() => {
          setShowCheck(false);
          clearTimeout(timer)
        }, 3000)
        dispatch(setUserData(response.data));
      }

    } catch (e) {
      console.log(e);
    }
  }

  return {
    showCheck,
    getAccountDetails,
    updateAccountDetails
  }
};

export default useAccount;
