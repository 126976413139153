import FormInput from "../FormInput/FormInput";
import styles from './CodeInput.module.scss';
import {useTranslation} from "react-i18next";

interface IProps {
  value: any;
  setValue: (value: string, pastFlag?: boolean) => void;
}

const CodeInput = ({ value, setValue }: IProps) => {
  const {i18n} = useTranslation();

  const handleInputChange = (nextInputRef: any, prevInputRef: any) => (e: any) => {
  const nextInput = document.getElementById(nextInputRef);
  const prevInput = document.getElementById(prevInputRef);
  const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');

  if (value !== sanitizedValue) {
    e.target.value = sanitizedValue;
  }

  if (e.target.value.length === 1 && nextInput) {
    nextInput.focus();
  }
  if (e.target.value.length === 0 && prevInput) {
    prevInput.focus();
  }

  setValue(e);
};

  const handlePaste = (e: any) => {
    setValue(e, true);
  };

  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'

  return (
    <div className={styles.inputWrapper}>
      <FormInput
        dirData={dirData}
        className={styles.codeInput}
        setValue={handleInputChange('2', '')}
        value={value['1']}
        placeHolder={''}
        name={'code'}
        type={'text'}
        maxLength={1}
        id={'1'}
        onPaste={handlePaste}
        inputmode="numeric"
        pattern="[0-9]*"
      />
      <FormInput
        dirData={dirData}
        className={styles.codeInput}
        setValue={handleInputChange('3', '1')}
        value={value['2']}
        placeHolder={''}
        name={'code'}
        type={'text'}
        maxLength={1}
        id={'2'}
        onPaste={handlePaste}
        inputmode="numeric"
        pattern="[0-9]*"
      />
      <FormInput
        dirData={dirData}
        className={styles.codeInput}
        setValue={handleInputChange('4', '2')}
        value={value['3']}
        placeHolder={''}
        name={'code'}
        type={'text'}
        maxLength={1}
        id={'3'}
        onPaste={handlePaste}
        inputmode="numeric"
        pattern="[0-9]*"
      />
      <FormInput
        dirData={dirData}
        className={styles.codeInput}
        setValue={handleInputChange('', '3')}
        value={value['4']}
        placeHolder={''}
        name={'code'}
        type={'text'}
        maxLength={1}
        id={'4'}
        onPaste={handlePaste}
        inputmode="numeric"
        pattern="[0-9]*"
      />
    </div>
  )
}

export default CodeInput;
