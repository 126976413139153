const GreenPlus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={11}
      fill="none"
    >
      <path
        fill="#0AA"
        fillRule="evenodd"
        d="M3.333 1.611A1.111 1.111 0 0 1 4.444.5h1.112a1.111 1.111 0 0 1 1.11 1.111v2.222H8.89A1.111 1.111 0 0 1 10 4.944v1.112a1.111 1.111 0 0 1-1.111 1.11H6.667V9.39A1.111 1.111 0 0 1 5.556 10.5H4.444a1.111 1.111 0 0 1-1.11-1.111V7.167H1.11A1.111 1.111 0 0 1 0 6.056V4.944a1.111 1.111 0 0 1 1.111-1.11h2.222V1.61Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default GreenPlus;
